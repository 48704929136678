<template>
  <scroll-layout class="details">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <ykc-detail :tips="true" :isLine="true" :titles="[String(dealData(this.formData.name))]">
      <div slot="cvHead-lt" class="flex switch-box">
        <el-switch
          v-rbac="'asset:station:connection:enable'"
          class="switch1"
          active-color="#4D8CFD"
          inactive-color="#4A4A4A"
          active-text="启用"
          inactive-text="停用"
          :active-value="1"
          :inactive-value="0"
          v-model="formData.startStatus"
          @change="startClick"></el-switch>
        <el-switch
          v-rbac="'asset:station:connection:invisible'"
          class="switch2"
          active-color="#4D8CFD"
          inactive-color="#4A4A4A"
          active-text="APP可见"
          inactive-text="APP不可见"
          :active-value="1"
          :inactive-value="0"
          v-model="formData.isAppDisplay"
          @change="soAppClick"></el-switch>
      </div>
      <div slot="cvBody" class="flex form-detail">
        <div class="fl-left">
          <div class="imgBox">
            <viewer :images="this.formData.picsVOList" v-if="this.formData?.picsVOList?.length > 0">
              <img
                class="form-item-img pointer"
                v-for="(src, index) in this.formData.picsVOList"
                :src="src.url"
                :key="index" />
            </viewer>
            <img v-else class="form-item-img pointer" src="@/assets/images/stationBg.png" />
          </div>
          <div class="txtBox" :class="[this.formData.operateStatus === 0 ? 'txtBox-active' : '']">
            {{ this.formData.operateStatus === 0 ? '休业中' : '营业中' }}
          </div>
        </div>
        <div class="fl-right">
          <div class="fl-top">
            <ykc-form :label-position="'left'" label-width="90px" class="form-left">
              <ykc-detail-item :label="'电站编码：'">
                <span>{{ dealData(this.formData.stationId) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'归属商户：'">
                <span>{{ dealData(this.formData.operatorName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'电站位置：'">
                <span>
                  {{
                    this.formData.locationAddress === 1
                      ? '地上电站'
                      : this.formData.locationAddress === 2
                      ? '地下电站'
                      : ''
                  }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item :label="'电站类型：'">
                <span>
                  {{
                    this.formData.stationType === 1
                      ? '公共'
                      : this.formData.stationType === 50
                      ? '个人'
                      : this.formData.stationType === 100
                      ? '公共(专用)'
                      : this.formData.stationType === 101
                      ? '环卫(专用)'
                      : this.formData.stationType === 102
                      ? '物流(专用)'
                      : this.formData.stationType === 103
                      ? '出租车(专用)'
                      : this.formData.stationType === 255
                      ? '其他'
                      : ''
                  }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item :label="'建设场所：'">
                <span>
                  {{
                    this.formData.buildAddress == 1
                      ? '居民区'
                      : this.formData.buildAddress == 2
                      ? '公共机构'
                      : this.formData.buildAddress == 3
                      ? '企事业单位'
                      : this.formData.buildAddress == 4
                      ? '写字楼'
                      : this.formData.buildAddress == 5
                      ? '工业园区'
                      : this.formData.buildAddress == 6
                      ? '交通枢纽'
                      : this.formData.buildAddress == 7
                      ? '大型文体设施'
                      : this.formData.buildAddress == 8
                      ? '大型建筑配建停车场'
                      : this.formData.buildAddress == 9
                      ? '路边停车位'
                      : this.formData.buildAddress == 10
                      ? '城际高速服务区'
                      : this.formData.buildAddress == 255
                      ? '其他'
                      : ''
                  }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item :label="'站点标签：'">
                <template v-if="formData.stationLabelNameList?.length > 0">
                  <span class="tagBox" :key="item.id" v-for="item in formData.stationLabelNameList">
                    {{ item }}
                  </span>
                </template>
                <template v-else>——</template>
              </ykc-detail-item>
              <ykc-detail-item :label="'可用总电容：'">
                <span>{{ dealData(formData.capacity) }} KV·A</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'值守人员：'">
                <span>{{ dealData(formData.dutyCount) }}</span>
              </ykc-detail-item>
            </ykc-form>
            <ykc-form :label-position="'left'" label-width="110px" class="form-right">
              <ykc-detail-item :label="'是否独立报装：'">
                <span>
                  {{ formData.isAloneApply === 0 ? '否' : formData.isAloneApply === 1 ? '是' : '' }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item :label="'是否公共停车场：'">
                <span>
                  {{
                    formData.isPublicParkingLot === 0
                      ? '否'
                      : formData.isPublicParkingLot === 1
                      ? '是'
                      : ''
                  }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item :label="'电站地址：'">
                <span>
                  {{ formData.detailAddress }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item :label="'停车收费模式：'">
                <span>
                  {{
                    formData.parkFeeType == 1
                      ? '免费停车'
                      : formData.parkFeeType == 2
                      ? '收费停车'
                      : formData.parkFeeType == 3
                      ? '充电限免'
                      : ''
                  }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item :label="'停车收费提示：'">
                <span>{{ dealData(this.formData.parkFeeTip) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'场站辅助设备：'">
                <template
                  v-if="this.formData.isBarrierGate == 1 || this.formData.parkingLockFlag == 1">
                  <span class="tagBox" v-if="this.formData.isBarrierGate == 1">道闸</span>
                  <span class="tagBox" v-if="this.formData.parkingLockFlag == 1">地锁</span>
                </template>
                <template v-else>——</template>
              </ykc-detail-item>
              <ykc-detail-item :label="'入驻时间：'">
                <span>{{ dealData(this.formData.createTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'投运时间：'" class="last-list">
                <span>{{ dealData(this.formData.useTime) }}</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
          <div class="flex flex-fot">
            <span>备注说明：</span>
            {{ dealData(formData.remark) }}
          </div>
        </div>
      </div>
    </ykc-detail>
    <div class="tags">
      <ykc-button
        type="plain"
        class="tags-btn"
        v-show="activeName === 'first'"
        v-rbac="'asset:station:connection:tactics'"
        @click="btnClick">
        更多策略
      </ykc-button>
      <ykc-button
        type="plain"
        class="tags-btn"
        v-show="activeName === 'second'"
        v-rbac="'asset:station:connection:about'"
        @click="btnClick">
        查看活动
      </ykc-button>
      <ykc-button
        type="plain"
        class="tags-btn"
        v-show="activeName === 'third'"
        v-rbac="'asset:station:connection:equip'"
        @click="btnClick">
        设备管理
      </ykc-button>
    </div>
    <ykc-tabs :tabList="tabList" :active="activeName" @btnClick="btnClick" @tabClick="tabClick">
      <div slot="first">
        <ykc-table ref="YkcTable1" :data="firstTableData" :columns="firstTableColumns"></ykc-table>
      </div>
      <div slot="second">
        <ykc-table ref="YkcTable2" :data="secondTableData" :columns="secondTableColumns">
          <ykc-pagination
            align="right"
            slot="pagination"
            ref="YkcPagination2"
            :total="pageInfo2.total"
            :page-size.sync="pageInfo2.size"
            :current-page.sync="pageInfo2.current"
            @size-change="handlePaginationSizeChange2"
            @current-change="handlePaginationCurrentChange2"></ykc-pagination>
        </ykc-table>
      </div>
      <div slot="third">
        <ykc-table ref="YkcTable3" :data="thirdTableData" :columns="thirdTableColumns">
          <ykc-pagination
            align="right"
            slot="pagination"
            ref="YkcPagination3"
            :total="pageInfo3.total"
            :page-size.sync="pageInfo3.size"
            :current-page.sync="pageInfo3.current"
            @size-change="handlePaginationSizeChange3"
            @current-change="handlePaginationCurrentChange3"></ykc-pagination>
        </ykc-table>
      </div>
    </ykc-tabs>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { sessionGetItem } from '@/utils';
  import { directPage, stationPage, platformPriceActivity } from '@/service/apis';

  export default {
    name: 'gateManagementDetail',
    components: {},
    data() {
      return {
        roleType: null,
        stationId: null,
        activeName: 'first',
        formData: {
          startStatus: 0,
          isAppDisplay: 0,
        },
        tabList: [
          {
            label: '电站标价',
            name: 'first',
          },
          {
            label: '电站活动',
            name: 'second',
          },
          {
            label: '电站设备',
            name: 'third',
          },
        ],
        firstTableData: [],
        firstTableColumns: [
          {
            label: '时段',
            prop: 'priceStartPeriod',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span>
                    {row.priceStartPeriod}~{row.priceEndPeriod}
                  </span>
                );
              },
            },
          },
          { label: '电费(元/度)', prop: 'powerRate', minWidth: '150px' },
          { label: '服务费(元/度)', prop: 'serviceRate', minWidth: '150px' },
          { label: '总计单价(元/度)', prop: 'totalRate', minWidth: '150px' },
        ],
        secondTableData: [],
        secondTableColumns: [
          { label: '编码', prop: 'activityId', minWidth: '150px' },
          { label: '活动名称', prop: 'activityName', minWidth: '150px' },
          {
            label: '活动类型',
            prop: 'activityType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span class="row-item row-green">
                    {row.activityType === 1 ? '平台活动' : '商户活动'}
                  </span>
                );
              },
            },
          },
          { label: '开始时间', prop: 'startTime', minWidth: '150px' },
          { label: '结束时间', prop: 'endTime', minWidth: '150px' },
          {
            label: '状态',
            prop: 'status',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const statusData = [
                  {},
                  {
                    name: '进行中',
                  },
                  {
                    name: '未开始',
                  },
                  {
                    name: '已停止（手动停止）',
                  },
                  {
                    name: '已停止（自动到期）',
                  },
                ][Number(row.status)];

                if (!statusData) {
                  return '一';
                }
                return <span class="row-item row-green">{statusData.name}</span>;
              },
            },
          },
        ],
        pageInfo2: {
          current: 1,
          size: 10,
        },
        thirdTableData: [],
        thirdTableColumns: [
          { label: '终端编码', prop: 'gunCode', minWidth: '150px' },
          { label: '终端名称', prop: 'gunName', minWidth: '150px' },
          { label: '归属电站', prop: 'stationName', minWidth: '150px' },
          {
            label: '终端启停',
            prop: 'startStatus',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return row.startStatus === 1 ? (
                  <span class={['userStatus', 'status-blue']}>启用</span>
                ) : (
                  <span class={['userStatus', 'status-red']}>停用</span>
                );
              },
            },
            id: 3,
          },
          {
            label: '工作状态',
            prop: 'gunStatus',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = [
                  {
                    color: 'status-black',
                    name: '离线',
                  },
                  {
                    color: 'status-red',
                    name: '故障',
                  },
                  {
                    color: 'status-green',
                    name: '空闲',
                  },
                  {
                    color: 'status-blue',
                    name: '充电',
                  },
                ][row.gunStatus];

                if (!cls) {
                  return '一';
                }

                return <span class={['userStatus', cls.color]}>{cls.name}</span>;
              },
            },
            id: 4,
          },
          { label: '归属商户', prop: 'operatorName', minWidth: '150px' },
          { label: '品牌', prop: 'brandName', minWidth: '150px' },
          { label: '型号', prop: 'brandModelName', minWidth: '150px' },
        ],
        pageInfo3: {
          current: 1,
          size: 10,
        },
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    created() {
      this.stationId = Number(this.$route.query.stationId);
      this.roleType = sessionGetItem('roleType');
      this.requestDetailList();
      this.requestPriceList();
    },
    methods: {
      /**
       * 获取详情数据
       */
      requestDetailList() {
        stationPage
          .intDetail({ stationId: this.stationId })
          .then(res => {
            if (res !== null) {
              this.formData = res;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取电站标价
       */
      requestPriceList() {
        stationPage
          .findStationPrice({ stationId: this.stationId })
          .then(res => {
            if (res !== null) {
              this.firstTableData = res;
            } else {
              this.firstTableData = [];
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 根据电站ID查询进行中的活动列表
       */
      requestPlatformPriceActivityList() {
        platformPriceActivity
          .ongoingList({ stationId: this.stationId, ...this.pageInfo2 })
          .then(res => {
            if (res) {
              this.secondTableData = res.records;
              this.pageInfo2.total = res.total || 0;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 互联电桩分页查询
       */
      requestIntFindByPageList() {
        const pageInfos = (({ current, size }) => ({ current, size }))(this.pageInfo3);
        const params = {
          ...pageInfos,
          stationId: this.stationId,
        };
        directPage
          .intFindByPage(params)
          .then(res => {
            this.thirdTableData = res.records;
            if (res) {
              this.thirdTableData = res.records;
              this.pageInfo3.total = res.total || 0;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       *启用/停用
       */
      startClick(status) {
        if (status === 1) {
          YkcDialog({
            showTitle: false,
            showFooter: true,
            dialogType: 'feedback',
            desc: '确认启用该电站吗？',
            onCancel: done => {
              console.log(done);
              done();
              this.formData.startStatus = 0;
            },
            onConfirm: done => {
              console.log(done);
              stationPage
                .intStatusSwitch({ stationIdList: [this.stationId], startStatus: 1 })
                .then(res => {
                  console.log(res);
                  this.$message.success('电站启用成功');
                  this.requestDetailList();
                })
                .catch(err => {
                  console.log(err);
                  this.formData.startStatus = 0;
                });
              done();
            },
          });
        } else {
          YkcDialog({
            showTitle: false,
            showFooter: true,
            dialogType: 'feedback',
            desc: '停用后，电站将不对外显示，同时无法启动充电！不影响正在充电的订单',
            onCancel: done => {
              console.log(done);
              done();
              this.formData.startStatus = 1;
            },
            onConfirm: done => {
              console.log(done);
              stationPage
                .intStatusSwitch({ stationIdList: [this.stationId], startStatus: 0 })
                .then(res => {
                  console.log(res);
                  this.$message.success('电站停用成功');
                  this.requestDetailList();
                })
                .catch(err => {
                  console.log(err);
                  this.formData.startStatus = 1;
                });
              done();
            },
          });
        }
      },
      /**
       * app是否可见
       */
      soAppClick(status) {
        if (status === 1) {
          YkcDialog({
            showTitle: false,
            showFooter: true,
            dialogType: 'feedback',
            desc: '确认设置app可见吗？',
            onCancel: done => {
              console.log(done);
              done();
              this.formData.isAppDisplay = 0;
            },
            onConfirm: done => {
              console.log(done);
              stationPage
                .updateAppShow({ stationId: this.stationId, isAppDisplay: 1 })
                .then(res => {
                  console.log(res);
                  this.requestDetailList();
                  this.$message.success('设置app可见成功');
                })
                .catch(err => {
                  console.log(err);
                  this.formData.isAppDisplay = 0;
                });
              done();
            },
          });
        } else {
          YkcDialog({
            showTitle: false,
            showFooter: true,
            dialogType: 'feedback',
            desc: '确认设置app不可见吗？',
            onCancel: done => {
              done();
              this.formData.isAppDisplay = 1;
            },
            onConfirm: done => {
              stationPage
                .updateAppShow({ stationId: this.stationId, isAppDisplay: 0 })
                .then(res => {
                  console.log(res);
                  done();
                  this.$message.success('设置app不可见成功');
                  this.requestDetailList();
                })
                .catch(err => {
                  console.log(err);
                  this.formData.isAppDisplay = 1;
                });
              done();
            },
          });
        }
      },
      /**
       * 组件change方法调用信息提示
       */
      tabClick(e) {
        if (e === 'first') {
          this.activeName = 'first';
          this.headerButtonText = '更多策略';
          this.headerButtonTextRbac = 'asset:station:connection:tactics';
          this.requestPriceList();
        } else if (e === 'second') {
          this.activeName = 'second';
          this.headerButtonText = '查看活动';
          this.headerButtonTextRbac = 'asset:station:connection:about';
          this.requestPlatformPriceActivityList();
        } else if (e === 'third') {
          this.activeName = 'third';
          this.headerButtonText = '设备管理';
          this.headerButtonTextRbac = 'asset:station:connection:equip';
          this.requestIntFindByPageList();
        }
      },
      /**
       *  右边按钮点击事件
       */
      btnClick(e) {
        if (e === 'first') {
          const routeUrl = this.$router.resolve({
            path: '/operationCenter/priceManage/chargingPriceStrategy',
            query: {
              stationName: this.formData.name,
            },
          });
          window.open(routeUrl.href, '_blank');
        } else if (e === 'second') {
          console.log('this.roleType+++', this.roleType);
          let url = '';
          if (Number(this.roleType) === 1) {
            url = '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity';
          } else if (Number(this.roleType) === 2) {
            url = '/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity';
          }
          const routeUrl = this.$router.resolve({
            path: url,
            query: {
              stationName: this.formData.name,
            },
          });
          window.open(routeUrl.href, '_blank');
        } else if (e === 'third') {
          const routeUrl = this.$router.resolve({
            path: '/plantAssets/powerPileManagement/interconnectionPile/List',
            query: {
              stationId: this.formData.stationId,
            },
          });
          window.open(routeUrl.href, '_blank');
        }
      },
      /**
       * table---2
       * */
      handlePaginationCurrentChange2(current) {
        this.pageInfo2.current = current;
        this.requestPlatformPriceActivityList();
      },
      /**
       * table---2
       * */
      handlePaginationSizeChange2(size) {
        this.pageInfo2.size = size;
        this.requestPlatformPriceActivityList();
      },
      /**
       * table---3
       * */
      handlePaginationCurrentChange3(current) {
        this.pageInfo3.current = current;
        this.requestIntFindByPageList();
      },
      /**
       * table---3
       * */
      handlePaginationSizeChange3(size) {
        this.pageInfo3.size = size;
        this.requestIntFindByPageList();
      },
      /**
       *
       */
      dealData(data) {
        return data || '——';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .details .ykc-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .last-list > div {
    margin-bottom: 0px !important;
  }
  .tagBox {
    margin-right: 8px;
    padding: 4px 8px;
    height: 14px;
    line-height: 14px;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4d8cfd !important;
    background: rgba(77, 140, 253, 0.1);
  }
  .switch-box {
    > div {
      margin-left: 10px;
    }
  }
  ::v-deep .el-switch__label * {
    font-size: 10px;
  }
  ::v-deep .el-switch__core:after {
    top: 3px;
    left: 4px;
    width: 12px;
    height: 12px;
  }

  ::v-deep.switch1 .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
    font-size: 10px;
  }
  /*打开时文字位置设置*/
  ::v-deep.switch1 .el-switch__label--right {
    z-index: 1;
    right: 22px;
  }
  /*关闭时文字位置设置*/
  ::v-deep.switch1 .el-switch__label--left {
    z-index: 1;
    left: 22px;
  }
  /*显示文字*/
  ::v-deep.switch1 .el-switch__label.is-active {
    display: block;
  }
  ::v-deep.switch1 .el-switch__core,
  .el-switch .el-switch__label {
    width: 60px !important;
  }

  ::v-deep.switch2 .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  /*打开时文字位置设置*/
  ::v-deep.switch2 .el-switch__label--right {
    z-index: 1;
    right: 32px;
  }
  /*关闭时文字位置设置*/
  ::v-deep.switch2 .el-switch__label--left {
    z-index: 1;
    left: 22px;
  }
  /*显示文字*/
  ::v-deep.switch2 .el-switch__label.is-active {
    display: block;
  }
  ::v-deep.switch2 .el-switch__core,
  .el-switch .el-switch__label {
    width: 100px !important;
  }
  .form-detail {
    padding-bottom: 20px !important;

    .fl-left {
      padding: 0 40px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;
      .imgBox {
        width: 80px;
        height: 80px;
        overflow: hidden;
        > div {
          width: 100%;
          height: 100%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .txtBox {
        width: 52px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        background: #1779fe;
        border-radius: 10px 0px 10px 0px;
      }
      .txtBox-active {
        background: #d0021b;
      }
    }

    .fl-right {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;

      .fl-top {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .form-left,
        .form-right {
          width: 50%;
        }
      }

      .flex-fot {
        width: 100%;
        padding-top: 16px;
        line-height: inherit;
        color: #000;
        font-size: 12px;
        border-top: 1px solid rgba(94, 94, 94, 0.09);

        > span {
          min-width: 90px;
          display: inline-block;
          font-size: 12px;
          color: #9b9b9b;
          line-height: inherit;
        }
      }
    }
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .tags-btn {
      position: absolute;
      top: 30px;
      right: 40px;
      z-index: 9;
    }
  }

  .ykc-tabs {
    margin-top: 10px;
    padding: 20px !important;
    background: #ffffff;
  }

  .ykc-table {
    .el-table .el-table__row {
      .cell .userStatus {
        padding-left: 8px;
        position: relative;
      }

      .cell .userStatus::after {
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #d0021b;
        position: absolute;
        top: 5.5px;
        left: 0;
      }

      .cell .status-blue::after {
        background: #4d8cfd;
      }

      .cell .status-green::after {
        background: #02c498;
      }

      .cell .status-black::after {
        background: #9b9b9b;
      }
    }

    .sealBtn {
      color: #4d8cfd;
      cursor: pointer;
    }
  }
</style>
